<template>
  <div>
    <v-tabs
      background-color="deep-purple accent-4"
      center-active
      dark
      v-model="tab"
    >
      <v-tab key="cur">Current Logs</v-tab>
      <v-tab key="arch">Archive</v-tab>        
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="cur">
        <log-events-index-list :log_events="log_events" :initial_excluded_levels="excluded_levels" :initial_page_size="page_size"></log-events-index-list>
      </v-tab-item>
      <v-tab-item key="arch">
        <div class="p-3" v-for="log_event_export in log_event_exports" :key="log_event_export.id">
          <v-chip :href="log_event_export.link_path" target="_blank">
            {{log_event_export.file_name}} ({{log_event_export.size}} MB)
          </v-chip>
        </div>          
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  export default {
    name: 'LogEventsIndex',
    data: () => ({
      tab: "cur"
    }),
    props: {
      log_events: Array,
      log_event_exports: Array,
      excluded_levels: Array,
      page_size: Number
    }
  }
</script>

