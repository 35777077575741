<template>
  <div>
    <input type="hidden" name="authenticity_token" :value="csrf_token" />
    <p>
      Verify the current configuration of this site. That is, check whether:
      <div>
        <v-icon :color="mapIconToColor(verifiedEcwidAPIAuthIcon)">{{verifiedEcwidAPIAuthIcon}}</v-icon>
        The Ecwid API Authentication details are correct.
      </div>
      <div>
        <v-icon :color="mapIconToColor(verifiedPlateAPIAuthIcon)">{{verifiedPlateAPIAuthIcon}}</v-icon>
        The Plate API Authentication details are correct.
      </div>
      <div>
        <v-icon :color="mapIconToColor(verifiedProductTypeMappingIcon)">{{verifiedProductTypeMappingIcon}}</v-icon>
        The product types that are mapped to Plate are actually present in Ecwid.
      </div>
    </p>
    <v-btn :loading="verifying" dark @click="verifySite">Verify configuration</v-btn>
  </div>
  
</template>

<script>
  import CSRFMixin from '../../mixins/csrf_token'

  export default {
    mixins: [CSRFMixin],
    name: 'SiteVerifyForm',
    data: () => ({
      verifying: false,
      verificationErrors: {},
      executedVerification: false
    }),
    computed: {
      verifiedPlateAPIAuthIcon(){return this.resolveVerificationStatusIcon("plate_api_auth")},
      verifiedEcwidAPIAuthIcon(){return this.resolveVerificationStatusIcon("ecwid_api_auth")},
      verifiedProductTypeMappingIcon(){return this.resolveVerificationStatusIcon("product_types_mapping")}
    },
    props: {
      verifyPath: String,
    },
    methods: {
      verifySite(){
        this.verifying = true
        this.$http.post(this.verifyPath)
          .then((result) => {
            this.verifying = false
            this.executedVerification = true
            let data = result.data.data
            
            if(!data.verified){
              this.verificationErrors = data.errors || {}
            }
          })
      },
      resolveVerificationStatusIcon(verificationKey){
        if(this.executedVerification){
          if(this.verificationErrors.hasOwnProperty(verificationKey) && this.verificationErrors[verificationKey].length > 0){
            return "warning"
          }else{
            return "check"
          }
        }else{
          return "help_outline"
        }
      },
      mapIconToColor(iconName){
        switch (iconName) {
          case "warning":
            return "error"
          case "check":
            return "green"
          default:
            return "gray"
        }
      }
    }
  }
</script>
