<template>
  <v-app>    
    <v-app-bar
      app
      dark          
    >
      <v-app-bar-nav-icon @click.stop="minifiedMenu = !minifiedMenu"></v-app-bar-nav-icon>

      <v-toolbar-title>Plate Commerce Connector</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon href="/users/edit"><v-icon>person</v-icon></v-btn>

      <form action="/users/logout" method="POST">
          <input type="hidden" name="_method" value="DELETE"/>
          <input type="hidden" name="authenticity_token" :value="csrf_token" />
          <v-btn icon type="submit"><v-icon>lock_open</v-icon></v-btn>
      </form>
    </v-app-bar>
    <v-navigation-drawer
      floating
      app
      permanent
      :mini-variant.sync="minifiedMenu"
      >
        <v-list-item class="px-2" v-if="!minifiedMenu">
          <v-btn
            icon
            @click="minifiedMenu = !minifiedMenu"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            :href="item.href"
            :target="item.target"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid>
          <slot></slot>
        </v-container>
      </v-main>
  </v-app>
</template>

<script>
  import CSRFMixin from '../../mixins/csrf_token'

  export default {
    mixins: [CSRFMixin],
    name: 'AppWrapper',
    data: () => ({
      minifiedMenu: true
    }),
    methods: {
      toggleMenu () {
        this.minifiedMenu = !this.minifiedMenu
      }
    },
    props: {
      menuItems: Array
    }
  }
</script>

<style lang="scss" scoped>
</style>
