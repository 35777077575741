<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="">{{ site.name }}</div>
      </v-card-title>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        grow
      >
        <v-tab key="config">
          Configuration
        </v-tab>
        <v-tab key="psp">
          Payment Service Providers
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="config">
          <v-card-text class="bg-gray-100 border-t border-b pt-5">        
            <v-text-field label="ID" readonly :value="site.id"></v-text-field>
            <v-text-field label="Name" readonly :value="site.name"></v-text-field>
            <v-text-field label="Status" readonly :value="site.status"></v-text-field>
            

            <v-textarea
              label="Plate Configuration" :value="JSON.stringify(site.plate_configuration)" 
              readonly
              outlined
              > 
            </v-textarea>
            <v-textarea
              label="Plate API Authentication" :value="JSON.stringify(site.plate_api_auth)" 
              readonly
              outlined
              > 
            </v-textarea>
            <v-textarea
              label="Ecwid Configuration" :value="JSON.stringify(site.ecwid_configuration)" 
              readonly
              outlined
              > 
            </v-textarea>
            <v-textarea
              label="Ecwid API Authentication" :value="JSON.stringify(site.ecwid_api_auth)" 
              readonly
              outlined
              > 
            </v-textarea>
          </v-card-text>            
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :href="'/dashboard/sites/' + site.id + '/edit'">Edit</v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item key="psp">
          <v-card-text class="bg-gray-100 border-t border-b pt-5">
            <ppc-index :currentPPCs="site.current_ppcs" :site-id="site.id"></ppc-index>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
  
</template>

<script>
  import CSRFMixin from '../../mixins/csrf_token'

  export default {
    name: 'SiteShow',
    data: () => ({
      tab: 0
    }),
    computed: {

    },
    props: {
      site: Object,
    },
    methods: {
    }
  }
</script>
