<template>
  <v-dialog
    v-model="dialog"
    max-width="690"
  >
    <v-card>
      <v-card-title class="headline">
        Edit {{name}}
      </v-card-title>

      <v-card-text>
        <v-textarea
          label="Payment Provider Authentication Configuration" 
          v-model="auth"
          outlined
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>

        <v-btn
          color="green darken-1"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>

<script>
  export default {
    name: 'PPCEdit',
    data: () => ({
      dialog: false,
      name: "",
      auth:  "",
      id: 0,
      siteId: 0
    }),
    computed: {
      parsedAuth(){
        return JSON.parse(this.auth)
      }
    },
    methods: {
      open(siteId, id){        
        this.id = id
        this.siteId = siteId
        this.$http.get(this.resourceUrl())
          .then((result) => {
            this.dialog = true
            let data = result.data.data
            this.name = data.name
            this.auth = JSON.stringify(data.auth)
          })
      },
      save(){
        this.$http.put(this.resourceUrl(), {
          auth: this.parsedAuth
        }).then((result) => {
          let response = result.data
          if(response.errors){
            console.log("ERROR: " + response.errors)
          }else{
            this.dialog = false
          }
        })
      },
      resourceUrl(){
        return "/dashboard/sites/" + this.siteId + "/payment_provider_configurations/" + this.id
      }
    }
  }
</script>
