<template>
  <v-simple-table dark>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="w-2"></th>
          <th class="w-2">ID</th>
          <th class="text-left">Naam</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="site in sites" :key="site.id" @click="redirect(site.link_path)" class="cursor-pointer" >
          <td>
              <v-icon >edit</v-icon>
          </td>
          <td>{{site.id}}</td>
          <td>{{site.name}}</td>
          <td>{{site.status}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  export default {
    name: 'SitesTable',
    methods: {
      redirect (path) {
        window.location.href = path
      }
    },
    props: {
      sites: Array
    }
  }
</script>
