<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="530"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        Start Full Synchronization
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline break-normal">
        Are you sure you want to start a Full Sync of this site? 
      </v-card-title>
      <v-card-text>
        This might potentially remove products or categories from the site in Plate. Only proceed if you
        are sure about what you are doing.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="dialog = false"
        >
          No, get me out of here!
        </v-btn>
        <form :action="submitPath" method="POST">
          <input type="hidden" name="_method" :value="formMethod" />
          <input type="hidden" name="authenticity_token" :value="csrf_token" />
          <v-btn type="submit" text @click="dialog = false">Agree</v-btn>    
        </form>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>

<script>
  import CSRFMixin from '../../mixins/csrf_token'

  export default {
    mixins: [CSRFMixin],
    name: 'SiteFullSyncForm',
    data: () => ({
      dialog: false
    }),
    props: {
      submitPath: String,
      formMethod: String
    },
    methods: {
    }
  }
</script>
