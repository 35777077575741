<template>
  <div>
    <div class="flex flex-row">
      <span class="align-self-center">Disable levels:</span>
      <v-checkbox @change="updateFilters" dense v-model="excluded_levels" class="px-3" value="debug" label="Debug"></v-checkbox>
      <v-checkbox @change="updateFilters" dense v-model="excluded_levels" class="px-3" value="info" label="Info"></v-checkbox>
      <v-checkbox @change="updateFilters" dense v-model="excluded_levels" class="px-3" value="warning" label="Warning"></v-checkbox>
      <v-checkbox @change="updateFilters" dense v-model="excluded_levels" class="px-3" value="critical" label="Critical"></v-checkbox>
      <v-spacer></v-spacer>
      <span class="align-self-center mr-3"># Records: </span>
      <v-text-field
        @change="updateFilters"
        v-model="page_size"
        hide-details
        single-line
        style="max-width:100px"
        type="number"
      ></v-text-field>
    </div>
    <v-expansion-panels>
      <v-expansion-panel v-for="log_event in log_events" :key="log_event.id">
        <v-expansion-panel-header>
          <div>
            <v-chip :color="log_event.level_color">
              {{ log_event.level }}
            </v-chip>
            <b>[{{ log_event.created_at }}]</b>
            {{ log_event.message }}
            <v-chip color="light-blue" v-if="log_event.site_name">
              {{ log_event.site_name }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ unescape(log_event.additional_info) }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    name: 'LogEventsIndexList',
    data: () => ({
      page_size: Number,
      excluded_levels: ["info"]
    }),
    mounted(){
      this.excluded_levels = this.initial_excluded_levels || []
      this.page_size = this.initial_page_size
    },  
    methods: {
      unescape(input){
        return unescape(input).replace(/\+/g, ' ')
      },
      updateFilters(){
        let url_params = "?page_size=" + this.page_size
        for(let excluded_level in this.excluded_levels){
          url_params += "&excluded_levels[]=" + this.excluded_levels[excluded_level]
        }
        window.location.href = window.location.pathname + url_params
      }
    },
    props: {
      initial_excluded_levels: Array,
      initial_page_size: Number,
      log_events: Array
    }
  }
</script>

