<template>
  <div>
   <v-list flat>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(ppc) in enabledPPCs"
          :key="ppc.id"
        >
          <v-list-item-content @click="showEdit(ppc.id)">
            <v-list-item-title v-text="ppc.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="showDelete(ppc.id)">
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
        </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-btn
      v-if="unusedNames.length > 0"
      class="m-2"
      fab
      dark
      small
      color="primary"
      @click="showNew"
    >
      <v-icon >
        mdi-plus
      </v-icon>
    </v-btn>

    <ppc-edit ref="editDialog"></ppc-edit>
    <ppc-new 
      @payment-provider-configuration-created="enabledPPCs.push($event)" 
      ref="newDialog"></ppc-new>
    <ppc-delete 
      @payment-provider-configuration-deleted="enabledPPCs = enabledPPCs.filter(x => x.id !== $event)" 
      ref="deleteDialog"></ppc-delete>
  </div>
  
</template>

<script>
  export default {
    name: 'PPCIndex',
    data: () => ({
      enabledPPCs: [],
      deleteDialog: false
    }),
    computed: {
      enabledNames(){
        return this.enabledPPCs.map(x => x.name)
      },
      unusedNames(){
        return ["mollie", "multi_safe_pay", "stripe", "invoice"].filter(x => !this.enabledNames.includes(x))
      }
    },
    props: {
      currentPPCs: Array,
      siteId: Number
    },
    mounted(){
      this.enabledPPCs = this.currentPPCs
    },
    methods: {
      showEdit(id){
        this.$refs.editDialog.open(this.siteId, id)
      },
      showNew(){
        this.$refs.newDialog.open(this.siteId, this.unusedNames)
      },
      showDelete(id){
        this.$refs.deleteDialog.open(this.siteId, id)
      }
    }
  }
</script>
