<template>
  <v-dialog
    v-model="dialog"
    max-width="690"
  >
    <v-card>
      <v-card-title class="headline">
        New {{name}}
      </v-card-title>

      <v-card-text>
        <v-select
          :items="allowedNames"
          label="Name"
          v-model="name"
          :error-messages="formErrors.name"
          outlined
        ></v-select>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
              v-bind="attrs"
              v-on="on"
              label="Payment Provider Authentication Configuration" 
              v-model="auth"
              outlined
              :error-messages="formErrors.auth"
            ></v-textarea>
          </template>
          <span>
            Enter the Payment Provider Configuration details here as JSON.<br> I.e. {"api_key": "mykey"} for mollie, multi_safe_pay and stripe. <br>You can leave this field empty for invoice.
          </span>
        </v-tooltip>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>

        <v-btn
          color="green darken-1"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>

<script>
  export default {
    name: 'PPCNew',
    data: () => ({
      dialog: false,
      siteId: 0,
      name: "",
      auth:  "",
      allowedNames: [],
      formErrors: {auth: "", name: ""}
    }),
    computed: {
      parsedAuth(){
        if(this.auth === ""){
          return {}
        }else{
          try{
            return JSON.parse(this.auth)
          }catch(e){
            return undefined
          }
        }
      }
    },
    methods: {
      open(siteId, allowedNames){        
        this.siteId = siteId
        this.allowedNames = allowedNames
        this.dialog = true        
      },
      save(){
        this.resetErrors()
        if(this.parsedAuth === undefined){
          this.formErrors.auth = "Invalid JSON"
          return
        }
        if(this.name === ""){
          this.formErrors.name = "Pick an option"
          return
        }
        this.$http.post("/dashboard/sites/"+this.siteId+"/payment_provider_configurations/", {
          name: this.name,
          auth: this.parsedAuth
        }).then((result) => {
          let response = result.data
          if(response.errors){
            console.log("ERROR: " + response.errors)
          }else{
            this.$emit("payment-provider-configuration-created", {name: this.name, id: response.data.id})
            this.dialog = false
          }
        })
      },
      resetErrors(){
        this.formErrors = {auth: "", name: ""}
      }
    }
  }
</script>
