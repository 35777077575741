// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

import axios from 'axios'

Vue.prototype.$http = axios

import "../css/application.scss"
  
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import vuetify from '../plugins/vuetify'

import AppWrapper from '../components/layout/app_wrapper'
import SiteShow from '../components/sites/site_show'
import SitesTable from '../components/sites/sites_table'
import SiteForm from '../components/sites/site_form'
import SiteFullSyncForm from '../components/sites/site_full_sync_form'
import SiteVerifyForm from '../components/sites/site_verify_form'
import LogEventsIndexList from '../components/log_events/index_list'
import LogEventsIndex from '../components/log_events/index'
import PPCIndex from '../components/payment_provider_configuration/index'
import PPCEdit from '../components/payment_provider_configuration/edit'
import PPCNew from '../components/payment_provider_configuration/new'
import PPCDelete from '../components/payment_provider_configuration/delete'


// Vue.use(Vuex)
Vue.use(TurbolinksAdapter) 

Vue.component('app-wrapper', AppWrapper)
Vue.component('site-show', SiteShow)
Vue.component('sites-table', SitesTable)
Vue.component('site-form', SiteForm)
Vue.component('site-full-sync-form', SiteFullSyncForm)
Vue.component('site-verify-form', SiteVerifyForm)
Vue.component('log-events-index', LogEventsIndex)
Vue.component('log-events-index-list', LogEventsIndexList)
Vue.component('ppc-index', PPCIndex)
Vue.component('ppc-edit', PPCEdit)
Vue.component('ppc-new', PPCNew)
Vue.component('ppc-delete', PPCDelete)

// window.store = new Vuex.Store({
//   state: {
//     modals: {} 
//   }
// })

document.addEventListener('turbolinks:load', () => {
  // Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  const app = new Vue({
    vuetify,
  }).$mount('[data-behavior="vue"]')
})

axios.interceptors.request.use(function (config) {
  config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  return config;
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
