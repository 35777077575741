<template>
  <form :action="submit_path" method="POST">
    <input type="hidden" name="_method" :value="formMethod" />
    <input type="hidden" name="authenticity_token" :value="csrf_token" />
    <div class="w-full md:w-2/3 lg:w-1/2">
      <v-card>
        <v-card-title>
          <div class="md-title">{{formTitle}}</div>
        </v-card-title>

        <v-card-text>
          <v-text-field label="Name" name="site[name]" :value="name"></v-text-field>

          <v-select
            v-model="status"
            name="site[status]"
            label="Status"
            :item-text="(item) => item[0]"
            :item-value="(item) => item[0]"
            :items="statuses"
          >
          </v-select>

          <v-textarea
            label="Plate Configuration" name="site[plate_configuration]" 
            v-model="plate_configuration"
            outlined
            > 
          </v-textarea>
          <v-textarea
            label="Plate API Authentication" name="site[plate_api_auth]" 
            v-model="plate_api_auth"
            outlined
            > 
          </v-textarea>
          <v-textarea
            label="Ecwid Configuration" name="site[ecwid_configuration]" 
            v-model="ecwid_configuration"
            outlined
            > 
          </v-textarea>
          <v-textarea
            label="Ecwid API Authentication" name="site[ecwid_api_auth]" 
            v-model="ecwid_api_auth"
            outlined
            > 
          </v-textarea>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </form>

</template>

<script>
  import CSRFMixin from '../../mixins/csrf_token'

  export default {
    mixins: [CSRFMixin],
    name: 'SiteEditForm',
    props: {
      name: String,
      status: String,
      statuses: Array,
      submit_path: String,
      plate_configuration: String,
      plate_api_auth: String,
      ecwid_configuration: String,
      ecwid_api_auth: String,
      formTitle: String,
      formMethod: String
    }
  }
</script>
