<template>
  <v-dialog
    v-model="dialog"
    max-width="690"
  >
    <v-card>
     <v-card-title class="headline break-normal">
          Are you sure you want to delete this Payment Provider Configuration
        </v-card-title>
        <v-card-text>
          No orders using this payment provider will be processed anymore. Only proceed if you
          are sure about what you are doing.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            
            @click="dialog = false"
          >
            No, get me out of here!
          </v-btn>
          <v-btn type="submit" color="error" @click="deletePPC">Delete</v-btn>    
        </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>

<script>
  export default {
    name: 'PPCDelete',
    data: () => ({
      dialog: false,
      id: 0,
      siteId: 0
    }),
    methods: {
      open(siteId, id){        
        this.id = id
        this.siteId = siteId
        this.dialog = true
      },
      deletePPC(){
        this.$http.delete(this.resourceUrl()).then((result) => {
          let response = result.data
          if(response.errors){
            console.log("ERROR: " + response.errors)
          }else{
            this.$emit("payment-provider-configuration-deleted", this.id)
            this.dialog = false
          }
        })
      },
      resourceUrl(){
        return "/dashboard/sites/" + this.siteId + "/payment_provider_configurations/" + this.id
      }
    }
  }
</script>
